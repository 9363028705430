import React from 'react'
import { Link } from 'gatsby'

import { rhythm, scale } from '../../utils/typography'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Navbar } from '../navbar'
import { scaleToStyled } from '../../utils/scale-functions'
import ThemeToggler from '../theme/theme'
import ClientOnly from '../client-only'
import Spark from '../spark'

const Layout = ({ location, title, subTitle, children }) => {
  const Wrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: ${rhythm(36)};
    padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
    background-color: var(--colors-background);
    color: var(--colors-primary);
    box-shadow: 0px 2px 5px #aaa;

    a {
      box-shadow: none;
      color: var(--colors-link);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    small,
    strong span,
    a,
    div,
    th,
    td {
      font-family: Fira Sans;
    }

    .gatsby-code-title {
      padding: 0.5em 1em;

      background-color: #2d2d2d;
      color: #ddd;
      z-index: 0;

      border-top-left-radius: 0.3em;
      border-top-right-radius: 0.3em;
      box-shadow: 0px -1px #ddd inset;
      display: inline-block;
      width: 100%;
      margin-top: 1em;
    }

    .gatsby-highlight pre[class*='language-'] {
      background-color: #2d2d2d;
      margin: 0 0 1em 0;
      padding: 0 0 0.5em 0;
      overflow: initial;
      float: left; /* 1 */
      min-width: 100%; /* 2 */
    }
    .gatsby-highlight pre[class*='language-'].line-numbers {
      padding-left: 2.8em;
      span.line-numbers-rows {
        left: 10px !important;
      }
    }
  `

  const Content = styled.main`
    a {
      &:hover {
        text-shadow: 1px 5px 3px var(--colors-link-shadow);
        transition: text-shadow 0.3s;
      }
    }
  `

  const TitleSmall = styled.h2`
    background: var(--colors-titles);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 0;
  `

  const TitleBig = styled.h1`
    background: var(--colors-titles);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: Italic;
    margin-bottom: ${rhythm(1.5)};
    margin-top: 0;
    ${scaleToStyled(scale(1.2))}
  `
  const footerStyle = css`
    text-align: center;
    padding-top: 30px;
  `

  let headerStyle = css`
    text-align: center;
    padding-bottom: 30px;
    background: var(--colors-separator) left bottom var(--colors-background)
      no-repeat;
    background-size: 100% 5px;
  `

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <>
        <TitleBig>{title}</TitleBig>
        <TitleSmall>{subTitle}</TitleSmall>
      </>
    )
  } else {
    headerStyle = css`
      ${headerStyle};
      position: sticky;
      top: -1px;
      z-index: 3;
    `
    header = (
      <>
        <TitleSmall>
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </TitleSmall>
        <Navbar location={location} />
      </>
    )
  }
  return (
    <div>
      <Spark />
      <Wrapper>
        <header css={headerStyle}>
          <ClientOnly>
            <ThemeToggler />
          </ClientOnly>
          {header}
        </header>
        <Content>{children}</Content>
        <footer css={footerStyle}>
          <hr
            css={css`
              background-color: var(--colors-primary);
            `}
          />
          <p>
            © {new Date().getFullYear()}, Federico Gambarino - All Rights
            Reserved
          </p>
          <small>
            Made with{' '}
            <span role="img" aria-label="love">
              ❤️
            </span>{' '}
            with{' '}
            <a
              href="https://www.gatsbyjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gatsby
            </a>
          </small>
        </footer>
      </Wrapper>
    </div>
  )
}

export default Layout
