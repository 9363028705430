import React from 'react'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'
import styled from '@emotion/styled'

export const Navbar = ({ location }) => {
  const Menu = styled.nav`
    ul {
      list-style-type: none;
      margin: 0;

      li {
        display: inline-block;
        margin: 0 20px;
        margin-right: ${rhythm(1)};
        font-size: ${rhythm(0.7)};
      }
    }
    a {
      font-style: Italic;
      background: var(--colors-titles);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `

  const links = [
    { name: 'my blog', url: '/blog/' },
    { name: 'biography', url: '/bio/' },
    { name: 'tech profile', url: '/profile/' },
  ]

  return (
    <Menu>
      <ul>
        {links.map(l => {
          return (
            <li key={l.name}>
              {location.pathname === `${l.url}` ? (
                <strong>
                  <Link to={l.url}>{l.name}</Link>
                </strong>
              ) : (
                <Link to={l.url}>{l.name}</Link>
              )}
            </li>
          )
        })}
      </ul>
    </Menu>
  )
}
