export function scaleToStyled(obj) {
  let style = ''
  for (let key in obj) {
    const dashed = key
      .split('')
      .map(l => {
        return l === l.toUpperCase() ? `-${l.toLowerCase()}` : l
      })
      .join('')
    style += `${dashed}: ${obj[key]};`
  }
  return style
}
