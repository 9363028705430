import React from 'react'

import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

const Spark = ({ children }) => {
  const leftToRight = keyframes`
0% {
  top: ${Math.random() * 100}vh;
  right: 100%;
  opacity: 0.9;
}
100% {
  opacity: 0.3;
  top: ${Math.random() * 100}vh;
  right: 0%;
}`

  const SparkDiv = styled.div`
    z-index: -1;
    position: absolute;
    animation-name: ${leftToRight};
    animation-duration: ${Math.random() ** 2 * 5 + 15}s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    border-radius: 10px;
    background-color: var(--colors-bubble-primary);
    height: 20px;
    width: 20px;
  `
  return (
    <SparkDiv>
      <div
        css={css`
          height: 4px;
          width: 4px;
          background-color: var(--colors-bubble-secondary);
          border-radius: 2px;
          margin-top: 3px;
          margin-left: 12px;
          transform: skew(0, 25deg);
        `}
      ></div>
    </SparkDiv>
  )
}
export default Spark
