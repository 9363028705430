import React from 'react'
import './vars.css'
import { css } from '@emotion/core'

export default function ThemeToggler() {
  const getFirstTheme = () => {
    const savedTheme = window.localStorage.getItem('theme')
    return savedTheme === 'dark' ? savedTheme : 'light'
  }
  const [theme, setTheme] = React.useState(getFirstTheme)
  const nextTheme = theme === 'light' ? 'dark' : 'light'

  React.useEffect(() => {
    window.localStorage.setItem('theme', theme)
    document.body.dataset.theme = theme
  }, [theme])

  return (
    <button
      css={css`
        background-color: transparent;
        background-image: none;
        cursor: pointer;
        border: none;
        float: right;
        :focus {
          outline: 0;
        }
      `}
      onClick={() => setTheme(nextTheme)}
      title={`Activate ${nextTheme} mode`}
      aria-label={`Activate ${nextTheme} mode`}
    >
      {theme === 'light' ? '🌙' : '🌞'}
    </button>
  )
}
